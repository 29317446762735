@import "tailwindcss/base";
@import "tailwindcss/components";
.btn {
  @apply px-4 py-2 bg-red-600 text-white;
}
.active {
  @apply bg-black text-white;
}

@import "tailwindcss/utilities";

@import "flashnotice.scss";
