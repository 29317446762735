.flash_notice {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 251, 235);
  border-color: rgba(251, 191, 36);
  .icon {
    color: rgba(251, 191, 36);
  }
  .text {
    color: rgba(180, 83, 9);
    &:hover {
      color: rgba(217, 119, 6);
    }
  }
}
.flash_notice.green {
  @apply bg-green-100;
  .icon {
    @apply text-green-200;
  }
  .text {
    @apply text-green-800;
    &:hover {
      @apply text-green-800;
    }
  }
}
.flash_notice.red {
  @apply bg-red-100;
  @apply border-red-800;
  .icon {
    @apply text-red-800;
  }
  .text {
    @apply text-red-800;
    &:hover {
      @apply text-red-800;
    }
  }
}
.flash_notice.yellow {
  @apply bg-yellow-100;
  @apply border-yellow-800;
  .icon {
    @apply text-yellow-800;
  }
  .text {
    @apply text-yellow-800;
    &:hover {
      @apply text-yellow-800;
    }
  }
}

.flash_notice.blue {
  @apply bg-blue-100;
  @apply border-blue-800;
  .icon {
    @apply text-blue-800;
  }
  .text {
    @apply text-blue-800;
    &:hover {
      @apply text-blue-800;
    }
  }
}
